import axios from '@/utils/request'


// 首页学分列表
export const scoreTopList = (data) => {
  return axios.request({
    url: '/api/userScore/top',
    data,
    method: 'get'
  })
}

// 今日推荐
export const recommendToday = (data) => {
  return axios.request({
    url: '/api/commonCourseTask/daily/today',
    data,
    method: 'get'
  })
}

// 获取学分列表
export const getScoreList = (data) => {
  return axios.request({
    url: '/api/userScore/page',
    data,
    method: 'get'
  })
}

// 获取学习任务路径
export const getTaskPath = (data) => {
  return axios.request({
    url: '/api/learningTaskPath/current/taskPath/progress',
    data,
    method: 'get'
  })
}

//获取当前的学习任务
export const getTaskData = (data) => {
  return axios.request({
    url: '/api/userLearningTask/progress',
    data,
    method: 'get'
  })
}