<template>
  <div class="home_warp">
    <loading :loading="timesSpining"></loading>
    <!-- banner -->
    <a-carousel autoplay class="home__banner" :dots="false">
      <div v-for="item of data" :key="item.id">
        <!-- <img :src="getImageView(item.image)" @click="toDetail(item)" /> -->
        <img class="home__banner-img" :src="getImageView(item.image)" @click="toDetail(item)" />
      </div>
    </a-carousel>

      <!-- level -->
      <div class="home_level">
        <div class="home_ranking_title">
          <div class="ranking_title_name">学习任务</div>
          <div class="ranking_title_ic">
            <img class="qsy_img" src="@/assets/images/homeImg/titleLine.png" alt="" />
          </div>
        </div>
        <div class="level_box">
          <div class="level_task_tab">
            <learn-path :isShow="true" @getTaskId="getTaskId" />
          </div>
          <div v-if="learnData != null">
            <div class="level_header">
              <div class="level_header_ic">
                <img class="qsy_img" src="@/assets/images/homeImg/level_icon.png" alt="" />
              </div>
              <div class="level_header_cent">
                <div class="level_details">
                  <div class="level_details_title">任务关卡：</div>
                  <div class="level_details_name" v-if="learnData != null &&
                    learnData.learningTaskLevelProgressList != null
                    ">
                    {{
                      learnData.learningTaskLevelProgressList[
                        levelInfo.levelIndex
                      ].taskLevelName
                    }}
                  </div>
                  <div class="level_details_play">
                    <div class="level_details_playIc">
                      <img class="qsy_img" src="@/assets/images/homeImg/level_play.png" alt="" />
                    </div>
                    <div class="level_details_playName" v-if="learnData != null &&
                        learnData.learningTaskLevelProgressList != null
                        ">
                      {{
                        learnData.learningTaskLevelProgressList[
                          levelInfo.levelIndex
                        ].learningTaskSubTaskProgressList[levelInfo.courseIndex]
                          .levelSubTaskName
                      }}
                    </div>
                  </div>
                </div>
                <div class="level_check" @click="toDetails">
                  <div class="level_check_text">查看</div>
                  <div class="level_check_ic">
                    <img class="qsy_img" src="@/assets/images/homeImg/level_arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="level_drawing" :style="{'background': 'url(' + getImageView(learnData.mapBg) + ') 0% 0% / 100% 100% no-repeat'}">
              <div class="level_drawing_item" v-for="(item, index) in learnData.learningTaskLevelProgressList"
                :key="index" :style="{
                  left: item.position.x + 'px',
                  top: item.position.y + 'px',
                }" @click="videoDetails">
                <div class="level_item_div" v-if="item.progressStatus === 'FINISH'">
                  <img class="qsy_img" :src="imgs.done[index]" alt="" />
                </div>
                <div class="level_item_div" v-else-if="item.progressStatus === 'DOING'">
                  <img class="qsy_img" :src="imgs.finish[index]" alt="" />
                </div>
                <div class="level_item_div" v-else>
                  <img class="qsy_img" :src="imgs.nostart[index]" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="level_boxed" v-else>
            <img class="qsy_img" src="@/assets/images/nodata.png" alt="" />
          </div>
        </div>

      </div>

      <!-- ranking -->
      <div class="home_ranking">
        <div class="home_ranking_title">
          <div class="ranking_title_name">学习排行榜</div>
          <div class="ranking_title_ic">
            <img class="qsy_img" src="@/assets/images/homeImg/titleLine.png" alt="" />
          </div>
        </div>
        <div class="home_ranking_box">
          <div class="home_ranking_header">
            <div class="ranking_header_left">
              <div class="rank_user">
                <div class="rank_user_img">
                  <img class="qsy_img" src="@/assets/images/homeImg/rankingHead.png" alt="" />
                </div>
                <div class="rank_user_name">
                  {{ rankingData.userData.realname }}
                </div>
              </div>
              <div class="ranking_line">
                <img class="qsy_img" src="@/assets/images/homeImg/rankingLine.png" alt="" />
              </div>
              <div class="rank_num_box">
                <div class="rank_num_box_name">
                  {{ rankingData.userData.score==0?"暂无排名":rankingData.userData.rank }}
                </div>
                <div class="rank_num_box_desc">我的排名</div>
              </div>
              <div class="ranking_line">
                <img class="qsy_img" src="@/assets/images/homeImg/rankingLine.png" alt="" />
              </div>
              <div class="rank_num_box">
                <div class="rank_num_box_name">
                  {{ rankingData.userData.score }}
                </div>
                <div class="rank_num_box_desc">我的学分</div>
              </div>
            </div>
            <!-- <div class="ranking_header_right">
            <img
              class="qsy_img"
              src="@/assets/images/homeImg/arrow_right.png"
              alt=""
            />
          </div> -->
          </div>
          <div class="home_ranking_table">
            <a-table :columns="rankingData.columns" :rowKey="(record) => record.userId"
              :loading="rankingData.rankingLoading" :data-source="rankingData.data" :pagination="false">
              <template slot="num" slot-scope="text, records, index">
                <img v-if="(rankingData.pagination.current - 1) *
                  rankingData.pagination.pageSize +
                  Number(index) +
                  1 ===
                  1
                  " src="@/assets/images/homeImg/rank_a.png" class="rank_table_img" />
                <img v-else-if="(rankingData.pagination.current - 1) *
                  rankingData.pagination.pageSize +
                  Number(index) +
                  1 ===
                  2
                  " src="@/assets/images/homeImg/rank_b.png" class="rank_table_img" />
                <img v-else-if="(rankingData.pagination.current - 1) *
                  rankingData.pagination.pageSize +
                  Number(index) +
                  1 ===
                  3
                  " src="@/assets/images/homeImg/rank_c.png" class="rank_table_img" />
                <span v-else>{{
                  (rankingData.pagination.current - 1) *
                  rankingData.pagination.pageSize +
                  Number(index) +
                  1
                }}</span>
              </template>
            </a-table>
            <div class="ranking_page_box">
              <div :class="[
                    'ranking_page_first',
                    currentNumFirst ? '' : 'noCursor',
                    rankingData.rankingLoading ? 'btn_disabled' : '',
                  ]" @click="firstBtn">
                首页
              </div>
              <div class="ranking_page_center">
                <a-pagination v-model="rankingData.pagination.current" :total="rankingData.pagination.total"
                  :pageSize="rankingData.pagination.pageSize" @change="changeRankPage"
                  :disabled="rankingData.rankingLoading" />
              </div>
              <div :class="[
                'ranking_page_end',
                currentNumEnd ? '' : 'noCursor',
                rankingData.rankingLoading ? 'btn_disabled' : '',
              ]" @click="endBtn">
                尾页
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script src="https://static.yunzhijia.com/public/js/qing/latest/qing.js"></script>
<script>
import { getFileAccessHttpUrl } from "@/utils/tools";
import { getUserData } from "@/api/user"; //用户信息api
import { getScoreList } from "@/api"; //学分排行api
import { getTaskData, getTaskPath } from "@/api";
import { bannerList } from "@/api/home.js";
import { ACCESS_TOKEN, USER_ID } from "@/store/mutation-types.js";
import learnPath from "./components/learn-pathed.vue";
import loading from "@/components/loading";
import Vue from "vue";
import Stomp from "stompjs";
const MQTT_USERNAME = "admin"; // mqtt连接用户名
const MQTT_PASSWORD = "123456"; // mqtt连接密码
const MQTT_SERVICE = "ws://124.232.163.68:15674/ws"; // mqtt连接服务地址

const columns = [
  {
    title: "排名",
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "姓名",
    dataIndex: "userRealname",
    key: "userRealname",
    align: "center",
  },
  {
    title: "门店",
    dataIndex: "sysOrgName",
    key: "sysOrgName",
    align: "center",
  },
  {
    title: "岗位",
    dataIndex: "positionName",
    key: "positionName",
    align: "center",
  },
  {
    title: "学分",
    dataIndex: "score",
    key: "score",
    align: "center",
  },
];

export default {
  name: "Home",
  data() {
    return {
      data: [],
      timesSpining: false,
      learnData: {},
      taskId: "",
      taskPathId: "",
      ticket: "",
      token: null,
      name: null,
      avatar: null,
      rankingData: {
        columns: columns,
        data: [],
        rankingLoading: false,
        userData: {},
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
      levelInfo: {},
      imgs: {
        finish: [
          require("../../assets/images/homeImg/level/active_0.png"),
          require("../../assets/images/homeImg/level/active_1.png"),
          require("../../assets/images/homeImg/level/active_2.png"),
          require("../../assets/images/homeImg/level/active_3.png"),
          require("../../assets/images/homeImg/level/active_4.png"),
          require("../../assets/images/homeImg/level/active_5.png"),
          require("../../assets/images/homeImg/level/active_6.png"),
          require("../../assets/images/homeImg/level/active_7.png"),
          require("../../assets/images/homeImg/level/active_8.png"),
          require("../../assets/images/homeImg/level/active_9.png"),
        ],
        nostart: [
          require("../../assets/images/homeImg/level/nostart_0.png"),
          require("../../assets/images/homeImg/level/nostart_1.png"),
          require("../../assets/images/homeImg/level/nostart_2.png"),
          require("../../assets/images/homeImg/level/nostart_3.png"),
          require("../../assets/images/homeImg/level/nostart_4.png"),
          require("../../assets/images/homeImg/level/nostart_5.png"),
          require("../../assets/images/homeImg/level/nostart_6.png"),
          require("../../assets/images/homeImg/level/nostart_7.png"),
          require("../../assets/images/homeImg/level/nostart_8.png"),
          require("../../assets/images/homeImg/level/nostart_9.png"),
        ],
        done: [
          require("../../assets/images/homeImg/level/done_0.png"),
          require("../../assets/images/homeImg/level/done_1.png"),
          require("../../assets/images/homeImg/level/done_2.png"),
          require("../../assets/images/homeImg/level/done_3.png"),
          require("../../assets/images/homeImg/level/done_4.png"),
          require("../../assets/images/homeImg/level/done_5.png"),
          require("../../assets/images/homeImg/level/done_6.png"),
          require("../../assets/images/homeImg/level/done_7.png"),
          require("../../assets/images/homeImg/level/done_8.png"),
          require("../../assets/images/homeImg/level/done_9.png"),
        ],
      },
    };
  },
  created() {
    this.token = Vue.ls.get(ACCESS_TOKEN);
    if (this.token != null) {
      this.getImgData();
      this.getUserData(); //获取用户信息
      this.getRankList(); //获取学分排行
    } else {
      this.$notification.error({
        message: "温馨提示",
        description: "您还未登录, 请先登录!",
      });
      setTimeout(() => {
        this.$router.push({
          name: "login",
        });
      }, 1000);
    }
  },
  computed: {
    getImageView() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    },
    getFileAccessHttpUrl() {
      return (avatar) => {
        return getFileAccessHttpUrl(avatar);
      };
    },
    isLoading() {
      if (this.token) {
        return true;
      } else {
        return false;
      }
    },
    currentNumFirst() {
      if (this.rankingData.pagination.current !== 1) {
        return true;
      } else {
        return false;
      }
    },
    currentNumEnd() {
      let num =
        this.rankingData.pagination.total /
          this.rankingData.pagination.pageSize -
        this.rankingData.pagination.current;
      console.log("num", num);
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: { loading, learnPath },
  methods: {
    // 获取轮播图数据
    getImgData() {
      const paramter = {};
      Object.assign(paramter);
      bannerList({
        forwardType: "PC",
      })
        .then((res) => {
          this.data = res.result.records;
          console.log(this.data)
        })
        .finally(() => {
          // this.connect()
        });
    },
    toDetail(item) {
      if (item.forward != null) {
        if (item.clientType === "LIVE") {
          this.$router.push({
            name: "live",
            query: {
              id: item.forward,
            },
          });
        } else if (item.clientType === "VIDEO") {
          this.$router.push({
            name: "gardenDetails",
            query: { id: item.taskId, type: "BANNER_TASK" },
          });
        } else if (item.clientType === "LINK") {
          window.open(item.forward);
        } else if (item.clientType === "") {
        }
      } else {
        // this.$message.warning("暂未配置课程数据，请联系管理员。");
      }
    },
    onConnected(frame) {
      console.log("connected:" + frame);
      var exchange = "/exchange/login";
      this.client.subscribe(exchange, this.responseCallback, this.onFailed);
      console.log(frame);
    },
    onFailed(frame) {
      console.log("Failed" + frame);
    },
    responseCallback(frame) {
      console.log("得到的消息 msg=>" + frame.body);
      const data = JSON.parse(frame.body);
      console.log(data, "我是更新之后的数据");
      this.isShow = false;
      // 接收到服务器推送消息，向服务器定义的接收消息routekey路由rk_recivemsg发送确认消息
    },
    connect() {
      this.client = Stomp.client(MQTT_SERVICE);
      this.client.connect(
        MQTT_USERNAME,
        MQTT_PASSWORD,
        this.onConnected,
        this.onFailed
      );
    },
    getUserData() {
      // 获取用户信息
      getUserData().then((res) => {
        console.log("登录信息", res);
        this.rankingData.userData = res.result;
      });
    },
    getRankList() {
      // 获取学分排行
      this.rankingData.rankingLoading = true;
      const paramter = {
        pageNo: this.rankingData.pagination.current,
        pageSize: this.rankingData.pagination.pageSize,
      };
      getScoreList(paramter)
        .then((res) => {
          this.rankingData.data = res.result.records;
          this.rankingData.pagination.total = res.result.total;
        })
        .finally(() => {
          this.rankingData.rankingLoading = false;
        });
    },
    changeRankPage(page, pageSize) {
      // 点击上下页或者其他页的时候
      this.rankingData.pagination.current = page;
      this.rankingData.pagination.pageSize = pageSize;
      this.getRankList();
    },
    firstBtn() {
      if (this.rankingData.pagination.current == 1) {
        return;
      }
      this.rankingData.pagination.current = 1;
      this.getRankList();
    },
    endBtn() {
      if (
        Math.ceil(
          this.rankingData.pagination.total /
            this.rankingData.pagination.pageSize
        ) == this.rankingData.pagination.current
      ) {
        return;
      }
      this.rankingData.pagination.current = Math.ceil(
        this.rankingData.pagination.total / this.rankingData.pagination.pageSize
      );
      this.getRankList();
    },
    getLevelData() {
      // 获取用户的任务关卡信息
      getTaskData({
        taskId: this.taskId,
        taskPathId: this.taskPathId,
      }).then((res) => {
        this.learnData = res.result;
        Vue.ls.set("TASK_NAME", res.result.taskName);
        console.log("level", res.result.learningTaskLevelProgressList);
        let level =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount
              ]
            : res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount - 1
              ];
        console.log("levellevellevellevel", level);
        let levelIndex =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.finishedLevelCount
            : res.result.finishedLevelCount - 1;
        let courseIndex = null;
        for (let i = 0; i < level.learningTaskSubTaskProgressList.length; i++) {
          if (
            level.learningTaskSubTaskProgressList[i].progressStatus ==
            "NOT_START"
          ) {
            courseIndex = i;
            break;
          }
        }
        console.log("courseIndex", courseIndex != "");
        let levelInfo = {
          levelIndex: levelIndex,
          courseIndex:
            courseIndex != null
              ? courseIndex
              : level.learningTaskSubTaskProgressList.length - 1,
        };
        this.levelInfo = levelInfo;
        console.log("this.levelInfo", this.levelInfo);
        this.timesSpining = false;
      });
    },
    toDetails() {
      // 跳转去视频播放
      let learnData = this.learnData;
      let levelInfo = this.levelInfo;
      let ele =
        learnData.learningTaskLevelProgressList[levelInfo.levelIndex]
          .learningTaskSubTaskProgressList[levelInfo.courseIndex];
      let index = levelInfo.levelIndex;
      let id = levelInfo.courseIndex;
      let studyType =
        learnData.learningTaskLevelProgressList[levelInfo.levelIndex].studyType;
      let taskLevelId =
        learnData.learningTaskLevelProgressList[levelInfo.levelIndex]
          .taskLevelId;
      console.log("看下点击事件", ele, index, id, studyType, taskLevelId);
      if (ele.subTaskType === "COURSE") {
        this.$router.push({
          name: "videoDetails",
          query: {
            id: ele.levelSubTaskId,
            course: ele.levelSubTaskContentId,
            taskLevelId: taskLevelId,
          },
        });
      } else {
        this.$router.push({
          name: "exam",
          query: {
            id: ele.levelSubTaskContentId,
            subTask: ele.levelSubTaskId,
          },
        });
      }
    },
    videoDetails() {
      this.$router.push({
        name: "learn",
      });
    },
    getTaskId(data, type) {
      console.log("data,type", data, type);
      if (type == "1") {
        this.taskId = data;
        Vue.ls.set("TASK_ID", this.taskId);
        this.taskPathId = Vue.ls.get("TASK_PATH_ID");
        this.getLevelData();
      } else {
        this.timesSpining = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home__banner {
  width: 100%;
  &-img {
    width: 100%;
    height: auto;
    // object-fit: cover;
  }
}

.home_warp {
  .home_banner {
    width: 100%;
    .home_banner_img {
      width: 100%;
      height: 650px;
    }
  }

  .home_ranking {
    width: 1200px;
    margin: 18px auto 192px;

    .home_ranking_title {
      width: 100%;
      margin: 0 auto;

      .ranking_title_name {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #404040;
        text-align: center;
      }

      .ranking_title_ic {
        width: 68px;
        height: 2px;
        margin: 14px auto 39px;
      }
    }

    .home_ranking_box {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      padding: 24px;
      background: #ffffff;

      .home_ranking_header {
        width: 1153px;
        height: 125px;
        background: #5db4ff;
        box-shadow: 0px 5px 10px 0px #a7d6ff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ranking_header_left {
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 48px;

          .rank_user {
            display: flex;
            align-items: center;

            .rank_user_img {
              width: 55px;
              height: 55px;
            }

            .rank_user_name {
              font-size: 30px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              margin-left: 16px;
            }
          }

          .ranking_line {
            width: 2px;
            height: 63px;
            margin: 0 100px;
          }

          .rank_num_box {
            text-align: center;
            color: #ffffff;
            font-family: Microsoft YaHei;

            .rank_num_box_name {
              font-size: 30px;
              font-weight: bold;
            }

            .rank_num_box_desc {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }

        .ranking_header_right {
          width: 19px;
          height: 33px;
          margin-right: 24px;
        }
      }

      .home_ranking_table {
        margin-top: 24px;

        .ranking_page_box {
          margin: 49px auto 18px;
          display: flex;
          justify-content: center;

          .ranking_page_first {
            width: 50px;
            height: 31px;
            border: 1px solid #bfbfbf;
            text-align: center;
            line-height: 29px;
            font-size: 16px;
            font-weight: 400;
            color: #878787;
            border-radius: 31px;
            margin-right: 9px;
            cursor: pointer;
          }

          .ranking_page_end {
            width: 50px;
            height: 31px;
            border: 1px solid #bfbfbf;
            text-align: center;
            line-height: 29px;
            font-size: 16px;
            font-weight: 400;
            color: #878787;
            border-radius: 31px;
            margin-left: 9px;
            cursor: pointer;
          }

          .ranking_page_first:hover,
          .ranking_page_end:hover {
            border: 1px solid #5db4ff;
            color: #ffffff;
            background: #5db4ff;
          }

          .noCursor {
            cursor: not-allowed !important;
          }

          .noCursor:hover {
            border: 1px solid #bfbfbf;
            color: #878787;
            background: #ffffff;
          }

          .btn_disabled {
            background: #f5f5f5;
            border-color: #d9d9d9;
            color: #878787;
          }
        }
      }
    }
  }

  .home_level {
    width: 1200px;
    margin: 48px auto 30px;

    .home_ranking_title {
      width: 100%;
      margin: 0 auto;

      .ranking_title_name {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #404040;
        text-align: center;
      }

      .ranking_title_ic {
        width: 68px;
        height: 2px;
        margin: 14px auto 39px;
      }
    }

    .level_box {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      padding: 0 24px 24px;
      background: #ffffff;

      .level_task_tab {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 30px;
      }

      .level_header {
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        .level_header_ic {
          width: 54px;
          height: 41px;
          margin-right: 19px;
        }

        .level_header_cent {
          height: 41px;
          flex: 1;
          border-bottom: 1px solid #d9d9d9;
          display: flex;
          align-items: flex-start;

          .level_details {
            flex: 1;
            display: flex;
            align-items: center;
            margin-top: -4px;

            .level_details_title {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #808080;
            }

            .level_details_name {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #64b7ff;
            }

            .level_details_play {
              display: flex;
              align-items: center;
              margin-left: 23px;

              .level_details_playIc {
                width: 14px;
                height: 12px;
                margin-right: 5px;
              }

              .level_details_playName {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #64b7ff;
                margin-left: 1px;
              }
            }
          }

          .level_check {
            display: flex;
            margin-left: 20px;
            align-items: center;
            margin-top: -4px;
            cursor: pointer;

            .level_check_text {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #64b7ff;
              margin-right: 17px;
            }

            .level_check_ic {
              width: 12px;
              height: 20px;
            }
          }
        }
      }

      .level_drawing {
        width: 1152px;
        height: 426px;
        position: relative;

        .level_drawing_item {
          position: absolute;
          width: 142px;
          height: 241px;
        }
      }
    }

    .level_boxed {
      width: 1152px;
      height: 515px;
      background: #ffffff;
      overflow: hidden;
    }
  }
}

.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}

.rank_table_img {
  width: 38px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .ant-table-thead>tr>th {
  border-bottom: none;
  color: #989898;
  padding: 0 16px;
}

::v-deep .ant-table-thead {
  height: 70px;
  font-size: 24px;
  font-weight: bold;
  color: #989898;
}

::v-deep .ant-table-tbody>tr>td {
  color: #989898;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  padding: 0 16px;
}

::v-deep .ant-table-tbody {
  min-height: 700px;
}

::v-deep .ant-table-tbody>tr {
  height: 70px;
}

::v-deep .ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #def0ff;
}

::v-deep .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #def0ff !important;
}

::v-deep .ant-pagination-prev,
.ant-pagination-next {
  width: 31px;
  height: 31px;
  margin-right: 10px;
}

::v-deep .ant-pagination-prev .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}

::v-deep .ant-pagination-prev:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  background: #5db4ff;
}

::v-deep .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}

::v-deep .ant-pagination-next:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  font-size: 13px;
  background: #5db4ff;
}

::v-deep .ant-pagination-item {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  line-height: 29px;
}

::v-deep .ant-pagination-item a {
  padding: 0;
}

::v-deep .ant-pagination-item-active {
  border: 1px solid #5db4ff;
}

::v-deep .ant-pagination-item-active a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}

::v-deep .ant-pagination-item:hover {
  border: 1px solid #5db4ff;
}

::v-deep .ant-pagination-item:hover a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}

::v-deep .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #5db4ff;
}

::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf !important;
  color: #878787 !important;
  background: #ffffff !important;
}
</style>
<style>
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #5db4ff;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #5db4ff;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5db4ff;
}

::-webkit-scrollbar-corner {
  background: #5db4ff;
}
</style>
