<template>
    <div >
        <div class="mask" v-if="loading">
            <div class="loading">
                <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 48px" spin />
                </a-spin>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang = "less" scoped>
    .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 999;
    }
    .loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
