import axios from '@/utils/request'


// 获取消息列表
export const bannerList = (data) => {
    return axios.request({
        url: '/api/Banner/list',
        data,
        method: 'get'
    })
}
export const queryTreeList = (data) => {
    return axios.request({
        url: '/api/sys/sysDepart/queryTreeList',
        data,
        method: 'get'
    })
}
export const positionList = (data) => {
    return axios.request({
        url: '/api/sys/position/listAll',
        data,
        method: 'get'
    })
}
// /api/sys/position/listAll
///api/sys/sysDepart/queryTreeList
///api/depart/queryMyDeptTreeList