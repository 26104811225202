<template>
  <div class="task_tab">
    <div
      class="learn"
    >
      <div
        :class="['learn_task',active === index ? 'learn_task_active' : '']"
        v-for="(item, index) in taskPathData.taskCompleteRatioList"

        :key="index"
        @click="toTask(item, index)"
      >
      {{item.taskName}} <span :class="[item.progressStatus === 'FINISH' ? 'is_finsih' : item.progressStatus === 'DOING' ? 'is_doing' : 'is_nostart' ]" v-if="item.lockFlag === 0">( {{ item.progressStatus === 'FINISH' ? '已完成' : item.progressStatus === 'DOING' ? '进行中' : '未开始'}} )</span>
        <span v-if="item.lockFlag !== 0" class="no_open">(未开启)</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileAccessHttpUrl } from "@/utils/tools.js";
import { getTaskPath } from "@/api";
import Vue from "vue";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "学习发展"
    }
  },
  computed: {
    getFileAccessHttpUrl() {
      return url => {
        if (!url) {
          return require("@/assets/images/home/no_path.jpg");
        } else {
          return getFileAccessHttpUrl(url);
        }
      };
    }
  },
  data() {
    return {
      taskId: "",
      taskPathData: { mapBg: null, taskPathId: null },
      active: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getTaskPath().then(res => {
        // /api/learningTaskPath/current/taskPath/progress
        let lockFlag = 0; // 记录当前任务是否锁定
        console.log(res.result, "res");
        if (res.result === null) {
          this.$emit("getTaskId", '','2');
        } else {
          
          this.taskPathData = res.result;
          // 记录下学习路径id
          // debugger;
          Vue.ls.set(
            "TASK_PATH_ID",
            this.taskPathData.taskPathId != ""
              ? this.taskPathData.taskPathId
              : ""
          );
          let arr = this.taskPathData.taskCompleteRatioList;
          let isTask = arr.some((item, index) => {
            this.active = index;
            if (
              item.progressStatus === "NOT_START" ||
              item.progressStatus === "DOING"
            ) {
              // 1 未完成状态时记录下任务的锁定状态
              lockFlag = item.lockFlag;
              // 已解锁的最高级别学习任务, 取这个任务的id展示
              if (
                index + 1 <= arr.length - 1 &&
                item.lockFlag === 0 &&
                arr[index + 1].lockFlag === 1
              )
                return (this.taskId = item.learningTaskId);
            } else {
              // 2 当前任务已完成并且下一个任务为锁定时, 则取当前任务id展示
              if (
                index + 1 <= arr.length - 1 &&
                arr[index + 1].progressStatus === "NOT_START" &&
                arr[index + 1].lockFlag === 1
              )
                return (this.taskId = item.learningTaskId);
            }
          });
          // Vue.ls.set("LOCK_FLAG", lockFlag); // 记录下当前展示的任务是否能够
          if (isTask) {
            // 把符合上文两个条件中其中一个条件的id传出去
            this.$emit("getTaskId", this.taskId,'1');
          } else {
            //上文两个条件都不符合时执行
            if (lockFlag === 1) {
              // 没有完成的任务,并且所有任务都锁定时, 取第一个任务的id展示
              this.$emit("getTaskId", arr[0].learningTaskId,'1');
              return;
            }
            this.active = arr.length - 1;
            // 如果所有任务完成则展示最后一个
            // console.log(arr[arr.length - 1].learningTaskId, 'arr[arr.length - 1].learningTaskId')
            this.$emit("getTaskId", arr[arr.length - 1].learningTaskId,'1');
          }
        }
      });
    },
    // 点击切换任务
    toTask(item, index) {
      if (item.lockFlag === 1) {
        this.$message.warning("抱歉! 该任务还未开启,请联系管理员");
        return false;
      }
      this.$message.success("已切换至当前任务!");
      this.active = index;
      this.$emit("getTaskId", item.learningTaskId,'1');
    }
  }
};
</script>

<style lang = "scss" scoped>
.task_tab{
  .learn{
    height: 70px;
    width: 100%;
    display: flex;
    .learn_task{
      margin-right: 50px;
      font-size: 18px;
      line-height: 70px;
      text-align: left;
      position: relative;
      color: #333;
      cursor: pointer;
      span{
        font-size: 18px;
      }
      .no_open{
        color: #999 !important;
      }
      .is_doing{
        color: #5DB4FF !important;
        font-weight: 300 !important;
      }
      .is_finsih{
        color: #55BD81 !important;
        font-weight: bolder !important;
      }
      .is_nostart{
        color: #999 !important;
        font-weight: 300 !important;
      }
    }
    .learn_task_active{
      border-bottom: 2px solid #5DB4FF;
      color: #5DB4FF;
      font-weight: bold;
    }
  }
}

</style>
